$(function () {

  /****************************************
   * 実行
   ****************************************/

  // グローバス変数、定数
  const BREAKPOINT_SP = 768;

  /**
   * ロード、リサイズ時、ブラウザ幅からPC,SPを判定し、処理分け
   */
  $(window).on('load resize', function() {
    let winW = window.innerWidth;

    if( winW > BREAKPOINT_SP){
      // PCのみ処理
      // 若干パララックスの実行
      prallaxEffect();
    } else {
      // SPのみ処理
    }
  });

  /**
   * ロード時に先頭へ
   */
  $(window).load(function(){
    $('body, html').animate({scrollTop: 0}, 1, function(){
      // 先頭へ移動した後に
      $('.wrapper').css('opacity', 1.0);
      // オープニングアニメーション実行(TOP)
      openAnimation();
    });
  });

  /**
   * スクロールによる実行
   */
  $(window).on('scroll', function() {
    let scrollValue = $(window).scrollTop();
    // スクロールしたら追従CVの高さ変更を実行
    HeightChagefixedCV(scrollValue);
    // サイト名・ページトップへの要素、あるエリアで反転を実行
    StyleChangeFixedTtlToppage(scrollValue);
  });


  /**
   * グロナビメニュー(OPEN)
   */
  (function () {
    $('#js-munu--open').on('click', function(){
      // スクロール禁止
      no_scroll();

      // openクラスはナビ開閉に使用
      $(this).addClass('open');
      $('#menu-overlay-outer').addClass('open');

      // 300ms後に実施
      setTimeout(function(){
        $('#header').addClass('open');
        $('#js-header-ttl').addClass('open');
        $('#fixed-cv').addClass('open');
      }, 300);

      // 400ms後に実施
      setTimeout(function(){
        $('#menu-overlay-content').addClass('open');
        // TODO: 後ほど関数化
        // twアイコン白色画像へ置換
        let img_tw = $('#js-header-tw').find('img');
        let src_on_tw = img_tw.attr('src').replace('--base', '--menu');
        img_tw.attr('src', src_on_tw);
        // fbアイコン白色画像へ置換
        let img_fb = $('#js-header-fb').find('img');
        let src_on_fb = img_fb.attr('src').replace('--base', '--menu');
        img_fb.attr('src', src_on_fb);
      }, 400);

      // 1000ms後に実施
      setTimeout(function(){
        $('.g-nav').addClass('open');
      }, 1000);

      // 1400ms後に実施
      setTimeout(function(){
        // 非表示で一旦出現
        $('#js-munu--close').addClass('open');
      }, 1400);
      // 1600ms後に実施
      setTimeout(function(){
        // ここで表示
        $('#js-munu--close').addClass('add_open');
      }, 1600);

      return false;
    });
  }).call(this);

  /**
   * グロナビメニュー(CLOSE)
   */
  (function () {
    $('#js-munu--close').on('click', function(){
      // スクロール復活
      return_scroll();

      // openクラスはナビ開閉に使用
      $(this).removeClass('open add_open');
      $('#js-munu--open').removeClass('open');
      $('#header').removeClass('open');

      $('.g-nav').removeClass('open');
      $('#menu-overlay-content').removeClass('open');
      $('#js-header-ttl').removeClass('open');
      $('#fixed-cv').removeClass('open');

      // TODO: 後ほど関数化
      // twアイコン白色画像へ置換
      let img_tw = $('#js-header-tw').find('img');
      let src_on_tw = img_tw.attr('src').replace('--menu', '--base');
      img_tw.attr('src', src_on_tw);
      // fbアイコン白色画像へ置換
      let img_fb = $('#js-header-fb').find('img');
      let src_on_fb = img_fb.attr('src').replace('--menu', '--base');
      img_fb.attr('src', src_on_fb);

      // 300ms後に実施
      setTimeout(function(){
        $('#menu-overlay-outer').removeClass('open');
      }, 300);

      return false;
    });
  }).call(this);

  /**
   * モーダルウィンドウ動画
   */
  new ModalVideo('.js-mv-open');

  /**
   * フッターCV領域にスクロールした際の表示切り替え
   */
  $('#footer-cv').on('inview', function(event, isInView, visiblePartX, visiblePartY) {
    if (isInView) {
      //追従CVフェードアウト
      $('#fixed-cv').stop().fadeOut(1000);
    } else {
      //追従CVフェードアウト
      $('#fixed-cv').stop().fadeIn(1000);
    }
  });

  /**
   * 「イントロ タイトル(TOP)」のスクロールアニメーション
   */
  $('.intro__content_ttl').on('inview', function(event, isInView, visiblePartX, visiblePartY) {
    if (isInView) {
      $(this).stop().addClass('intro_ttl_fadeIn');
    } else {
      //$(this).stop().removeClass('intro_ttl_fadeIn');
    }
  });

  /**
   * 「イントロテキスト(TOP)」のスクロールアニメーション
   */
  $('.intro__content_txt').on('inview', function(event, isInView, visiblePartX, visiblePartY) {
    if (isInView) {
      $(this).stop().addClass('intro_txt_fadeIn');
    } else {
      //$(this).stop().removeClass('intro_txt_fadeIn');
    }
  });

  /**
   * 画像のモーダルウィンドウ(メンバー)
   */
  // #1 メンバーグループ
  $(".eps1_member-g").colorbox({
    rel: '.eps1_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });
  // #2 メンバーグループ
  $(".eps2_member-g").colorbox({
    rel: '.eps2_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });
  // #3 メンバーグループ
  $(".eps3_member-g").colorbox({
    rel: '.eps3_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });
  // #4 メンバーグループ
  $(".eps4_member-g").colorbox({
    rel: '.eps4_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });
  // #5 メンバーグループ
  $(".eps5_member-g").colorbox({
    rel: '.eps5_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });
  // #6 メンバーグループ
  $(".eps6_member-g").colorbox({
    rel: '.eps6_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });
  // #7 メンバーグループ
  $(".eps7_member-g").colorbox({
    rel: '.eps7_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });
  // #8 メンバーグループ
  $(".eps8_member-g").colorbox({
    rel: '.eps8_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });
  // #9 メンバーグループ
  $(".eps9_member-g").colorbox({
    rel: '.eps9_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });
  // #10 メンバーグループ
  $(".eps10_member-g").colorbox({
    rel: '.eps10_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });

    // #11 メンバーグループ
  $(".eps11_member-g").colorbox({
    rel: '.eps11_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });

    // #12 メンバーグループ
  $(".eps12_member-g").colorbox({
    rel: '.eps12_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });

    // #13 メンバーグループ
  $(".eps13_member-g").colorbox({
    rel: '.eps13_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });

    // #14 メンバーグループ
  $(".eps14_member-g").colorbox({
    rel: '.eps14_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });

    // #15 メンバーグループ
  $(".eps15_member-g").colorbox({
    rel: '.eps15_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });

    // #16 メンバーグループ
  $(".eps16_member-g").colorbox({
    rel: '.eps16_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });

    // #17 メンバーグループ
  $(".eps17_member-g").colorbox({
    rel: '.eps17_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });

    // #18 メンバーグループ
  $(".eps18_member-g").colorbox({
    rel: '.eps18_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });

    // #19 メンバーグループ
  $(".eps19_member-g").colorbox({
    rel: '.eps19_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });

    // #20 メンバーグループ
  $(".eps20_member-g").colorbox({
    rel: '.eps20_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });

    // #21 メンバーグループ
  $(".eps21_member-g").colorbox({
    rel: '.eps21_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });

    // #22 メンバーグループ
  $(".eps22_member-g").colorbox({
    rel: '.eps22_member-g',
    //slideshow: true,
    //slideshowSpeed: 3000,
    maxWidth: "70%",
    maxHeight: "70%",
    opacity: 1.0
  });

  /*
   タブパネル切り替え
   */
  $('.js-tabSet').each(function(){

    let topDiv = $(this);
    let anchors = topDiv.find('#js-tab a');
    let panelDivs = topDiv.find('.js-panelbox');
    let lastAnchor;
    let lastPanel;

    // スムーズスクロールしないように無効果のクラスをつける
    anchors.addClass('js-no-s-scroll');

    lastAnchor = anchors.filter('.on');
    lastPanel = $(lastAnchor.attr('href'));

    panelDivs.hide();
    lastPanel.show();

    anchors.on('click', function(e){

      e.preventDefault();
      let currentAnchor = $(this);
      let currentPanel = $(currentAnchor.attr('href'));

      lastAnchor.removeClass('on');
      currentAnchor.addClass('on');

      lastPanel.hide();
      currentPanel.fadeIn(2000);

      lastAnchor = currentAnchor;
      lastPanel = currentPanel;
    });
  });

  /**
   * 画像切り替え
   */
  $('.js-pic-thum a').click(function(){
    if($(this).parent().hasClass('choice') === false){
      let targetGroup = $(this).attr("data-photogroup");
      $(this).parent().addClass('choice').siblings().removeClass('choice');
      let targetImg = $(this).find('img').attr("src");
      $("#" + targetGroup + " .js-pic-main img").hide().attr('src', targetImg).fadeIn(400);
    }
    return false;
  }).filter(':eq(0)').click();

  /**
   * スムーズスクロール
   */
  (function () {
    $('a[href^="#"]').click(function () {

      // "js-no-s-scroll"クラスがついていたら実行しない
      if($(this).hasClass('js-no-s-scroll')){
        return;
      }

      const speed = 500;
      const href = $(this).attr('href');
      const target = $(href == "#" || href == "" ? "html" : href);
      const position = target.offset().top;

      $('body, html').animate({
        scrollTop: position
      }, {
        duration: speed,
        easing: 'easeOutCubic'
      });
      return false;
    });
  }).call(this);


  /**
   * tw-night-overlay
   */
  $('.js-tw-night-overlay').on('click', function(){
    $('.tw-night_overlay').toggleClass('open');
    $('.tw-night_bnr_pc-top').toggleClass('open');
  });


  /****************************************
   * 関数
   ****************************************/

  /**
   * オープニングアニメーション(TOP)関数
   */
  function openAnimation(){

    $('.kv__logo').addClass('on');

    // 2100ms後に実施
    setTimeout(function(){
      $('.kv__main').addClass('on');
    }, 2100);

    // 2500ms後に実施
    setTimeout(function(){
      $('.fixed-pagename').addClass('on');
      $('.fixed-pagetop').addClass('on');
      $('.sec-bnr').addClass('on');
      $('.news,.sp__tub.only--sp').addClass('on');
    }, 2400);

    // 2500ms後に実施
    setTimeout(function(){
      $('.header').addClass('on');
      $('.fixed-cv').addClass('on');
    }, 2500);

    // 3200ms後に実施
    setTimeout(function(){
      $('.kv__txt').addClass('on');
    }, 3200);

  }

  /**
   * 若干パララックス関数
   */
  function prallaxEffect(){

    let $bgbox = $('.js-parallax_bg');

    if ($bgbox.length !== 0) {
      let $doc = $(document);
      let num = 0;
      let _box = [];
      let _t = [];
      let bp;

      for (let i = 0, len=$bgbox.length; i < len; i++) {
        let _top = $($bgbox[i]).offset().top;

        num = $doc.scrollTop();
        bp = _top - num ;
        _box.push($($bgbox[i]));
        _t.push(_top);
      }

      let scroll = window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        function(callback) {
          window.setTimeout(callback, 1000 / 60)
        };

      let lastPosition = -10;
      function loop() {
        if (lastPosition === window.pageYOffset) {
          scroll(loop);
          return false;
        } else lastPosition = window.pageYOffset;

        let _h = window.innerHeight;

        num = lastPosition;

        for (let i = 0,len=$bgbox.length; i < len; i++) {
          let bp = _t[i] - num ;
          if (Math.abs(bp) < _h) {
            $bgbox[i].style.backgroundPosition='0px '+bp/-18+'px';
          }
        }

        scroll(loop)
      }
      loop();
    }
  }

  /**
   * サイト名・ページトップへの要素、あるエリアで反転させる関数
   */
  function StyleChangeFixedTtlToppage(scrollValue){
    let $footCV = $('#footer-cv');
    let $pageName = $('.fixed-pagename');
    let $pageNameTxt = $('.fixed-pagename__txt');
    let $pageTop = $('.fixed-pagetop');

    let offsetTop_footer_cv = $footCV.offset().top;

    // サイト名
    let offsetTop_fixed_pagename = $pageName.position().top;
    let height_pagename = $pageName.height();
    let pagename_result = offsetTop_footer_cv - offsetTop_fixed_pagename;
    let pagename_scr_point = pagename_result - height_pagename;

    if (pagename_scr_point <= scrollValue) {
      $pageNameTxt.addClass('scroll-area');
    } else {
      $pageNameTxt.removeClass('scroll-area');
    }

    // ページトップへ
    let offsetTop_fixed_pagetop = $pageTop.position().top;
    let height_pagetop = $pageTop.height();

    let pagetop_result = offsetTop_footer_cv - offsetTop_fixed_pagetop;
    let pagetop_scr_point = pagetop_result - height_pagetop;

    let pagetom_img = $('.fixed-pagetop__item a').find('img');
    let pagetom_img_src_replace_on = pagetom_img.attr('src').replace('--black', '--white');
    let pagetom_img_src_replace_off = pagetom_img.attr('src').replace('--white', '--black');


    if (pagetop_scr_point <= scrollValue) {
      $("<img>").attr("src", pagetom_img_src_replace_on); // 画像プリロード
      pagetom_img.attr('src', pagetom_img_src_replace_on);
    } else {
      $("<img>").attr("src", pagetom_img_src_replace_off);  // 画像プリロード
      pagetom_img.attr('src', pagetom_img_src_replace_off);
    }
  }

  /**
   * (30px)スクロールしたら追従CVの高さ変更する関数
   */
  function HeightChagefixedCV(scrollValue){
    if(scrollValue > 30) {
      $('#js-fixed-cv-height').addClass('action-scroll');
    }else{
      $('#js-fixed-cv-height').removeClass('action-scroll');
    }
  }

  /**
   * 横幅から高さ算出関数
   * target_class:高さを求めるエレメントのクラス名
   * base_w:比率の基準となる横幅（基本はカンプのサイズ）
   * base_h:比率の基準となる高さ（基本はカンプのサイズ）
   */
  function getHeight(target_class, base_w, base_h){
    target_class = '.' + target_class;
    let w = $(target_class).width();
    // 横幅から高さ算出
    let h = (base_h * w) / base_w + "px";
    $(target_class).css('height', h);
  }

  /**
   * スクロール禁止用関数
   */
  function no_scroll(){
    // PC
    let scroll_event = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
    $(document).on(scroll_event,function(e){e.preventDefault();});
    // SP
    $(document).on('touchmove.noScroll', function(e) {e.preventDefault();});
  }

  /**
   * スクロール復活用関数
   */
  function return_scroll(){
    // PC
    let scroll_event = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
    $(document).off(scroll_event);
    // SP
    $(document).off('.noScroll');
  }

  /**
   * 【画像選択禁止の指定】画像をドラック&ドロップできなくなります
   */
  (function () {
    $('img').attr('onmousedown', 'return false');
  }).call(this);

  /*
   *シーズンの切り替えリンクの背景をホバーで移動させる
   */
   (function () {
     //pc
     let seasonnum = $('.wrapper').attr('class');
     seasonnum = 'hoverbtn' + seasonnum.match(/\d/g).join("");
     $('.header__tub_season').addClass(seasonnum);
     $('header .header__tub_season li').each(function(index) {
       let hovername = 'hoverbtn'+(index+1);
       $(this).hover(
         function() {
           $('header .header__tub_season').removeClass('hoverbtn1 hoverbtn2');
           $('header .header__tub_season').addClass(hovername);
         },
         function() {
           $('header .header__tub_season').removeClass('hoverbtn1 hoverbtn2');
           $('header .header__tub_season').addClass(seasonnum);
         }
       );
     });
   }).call(this);

});
